body {
  margin: 0;
  padding: 0;
  font-family: 'Work Sans', sans-serif !important;
}

#root {
  height: 100vh;
  width: 100vw;
}
